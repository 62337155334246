<template lang="pug">
  v-card(outlined)
    v-card-text.text-center.justify-center
      v-icon(color="secondary" large) mdi-card-account-details-outline
      div.text-body-1.font-weight-medium.mb-3 Datos de contacto
      v-row.pb-6(no-gutters justify="center")
        v-col(cols="12" md="8")
          div.text-body-1.primary--text Sus tickets se enviarán al correo electrónico.
          div.text-body-1.primary--text En caso de inconvenientes nos contactaremos con usted.
      v-form(ref="datos_contacto")
        v-row(align="start")
          v-col(cols="12" :md="pasaporte ? 4 : 4")
            v-select(label="Elegir pasajero" v-model="contacto", :items="pasajeros" item-text="nombre" item-value="index" outlined dense prepend-icon="mdi-account-multiple" color="secondary" required :rules="rules(true)")
          v-col(cols="12" :md="pasaporte ? 4 : 4")
            // v-text-field(label="RUT" outlined dense prepend-icon="mdi-account-box" color="secondary", :disabled="contacto === null")
            v-text-field(v-model="correo" label="Correo" outlined dense prepend-icon="mdi-email" color="secondary" required :rules="rules(true, 'correo')" :disabled="contacto === null" validate-on-blur)
          v-col(cols="12" :md="pasaporte ? 4 : 4")
            // v-text-field(label="Nombre" outlined dense prepend-icon="mdi-account" color="secondary", :disabled="contacto === null")
            // v-text-field(v-model="telefono" label="Teléfono" outlined dense prepend-icon="mdi-cellphone" color="secondary" required :rules="rules(!sin_telefono, 'telefono')", :disabled="contacto === null || sin_telefono" type="text" prefix="+56" v-mask="'#########'" validate-on-blur)
            TelInput(v-model="telefono" label="Teléfono" @on-change-country="onChangeCountry" :preferredCountries="['CL', 'AR', 'BO', 'BR', 'CO', 'EC', 'MX', 'PE', 'VE']" defaultCountry="CL" dense @on-error="onErrorPhone" :errorManual="errorPhoneManual")
          // v-col(v-if="tipoDoc === 'Pasaporte'" cols="12" :md="pasaporte ? 6 : 4")
            v-checkbox(v-model="sin_telefono" label="No tengo teléfono chileno" color="secondary" @change="onChangeTel")
          // v-col(cols="12")
            v-expand-transition
              div.text-body-2.text-sm-body-1.font-italic.red--text(v-if="sin_telefono") En caso de suspensión o reprogramación la información se enviará al <b>correo electrónico</b>.
</template>

<script>

import TelInput from '@/components/UI/TelInput'

export default {
  name: 'WidgetContacto',

  components: {
    TelInput
  },

  data: () => ({
    contacto: 0,
    correo: '',
    telefono: '',
    errorPhone: false,
    errorPhoneManual: false,
    area: '',
    tipoDoc: '',
    sin_telefono: false
  }),

  watch: {
    contacto (val) {
      if (val !== null) {
        this.setDatos(val)
      }
    }
  },

  computed: {
    pasajeros () {
      return this.$store.state.pasajerosArr.map(function(p, index) {
        return {
          nombre: (p.nombre || '') + ' ' + ( p.apellido || ''),
          index
        }
      })
    },

    pasaporte () {
      return this.tipoDoc === 'Pasaporte'
    }
  },

  mounted () {
    this.setDatos(0)
  },

  methods: {
    setDatos (val) {
      const pax = this.$store.state.pasajerosArr[val]
      this.correo = pax.correo
      let telefono = pax.telefono
      if (telefono && !pax.telefono.startsWith('+')) {
        telefono = '+56' + telefono
      }
      this.telefono = telefono || ''
      this.tipoDoc = pax.tipoDoc
      this.sin_telefono = pax.sin_telefono
      this.$store.commit('SET_INDEX_CONTACTO', val)
    },

    onChangeTel () {
      this.telefono = ''
    },

    onChangeCountry ({ area }) {
      this.area = area
    },

    validar () {
      this.errorPhoneManual = !this.area || !this.telefono

      const valido = this.$refs['datos_contacto'].validate() && !this.errorPhoneManual && !this.errorPhone
      if (valido && this.contacto !== null) {
        const pax = this.$store.state.pasajerosArr[this.contacto]
        let telefono = this.telefono
        if (this.pasaporte && this.sin_telefono) {
          telefono = '999999999'
        }
        const datos = {
          rut: pax.rut,
          nombre: pax.nombre,
          apellido: pax.apellido,
          correo: this.correo,
          telefono: '+' + this.area + telefono
        }
        this.$store.commit('SET_DATOS_CONTACTO', datos)
      } else {
        this.$store.commit('SET_DATOS_CONTACTO', {})
      }
      return valido
    },

    onErrorPhone (err) {
      this.errorPhone = err
    }
  }
}

</script>

<style type="text/css">

</style>
