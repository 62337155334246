<template lang="pug">
  v-card
    v-card-text
      v-tabs(v-if="tipo_viaje === 'vuelta'" v-model="tab" color="secondary" background-color="primary" slider-color="secondary" slider-size="8" fixed-tabs dark height="60")
        v-tab(v-for="(trayecto, t) in itinerarios", :key="t")
          div(ref="divtab") {{ tipo(t) }}
          v-badge.ml-2(v-if="errores[t]" color="error" dot)
      v-tabs-items(v-model="tab")
        v-tab-item(v-for="(trayecto, t) in itinerarios", :key="t")
          div.my-6.text-h6.font-weight-light(v-if="trayecto === undefined || trayecto.length === 0")
            div No existen viajes disponibles para la ruta <b>{{ ruta(t) }}</b> en la fecha seleccionada.
            div.mt-3 Escoja otros puertos de origen y destino u otras fechas de viaje.
          v-row.mx-3(v-else :justify="$vuetify.breakpoint.smAndDown ? 'center' : 'space-between'")
            v-col(cols="12" sm="4")
              div.mt-6.text-h6.font-weight-light Elija un viaje de <b>{{ tipo(t) }}</b>:
            v-col(cols="10" sm="8" md="6")
              OrigenDestino(:origen="origen" :destino="destino")
          v-item-group(v-model="seleccionado[tab]")
            v-item(v-for="(itinerario, i) in trayecto", :key="i" v-slot="{ active, toggle }" active-class="it-borde-act")
              v-hover(:disabled="!bloqueo_dinamico(itinerario) && disponibilidad(itinerario)")
                template(v-slot:default="{ hover }")
                  v-card.it-borde-sec.ma-3(@click="bloqueo_dinamico(itinerario) ? null : toggle()" outlined elevation="6")
                    v-card-text.pa-1
                      v-row.text-center(align="center" no-gutters)
                        v-col(cols="12" sm="6")
                          div.text-overline.primary--text Zarpe
                          div.mt-n1.text-body-1 {{ $moment(itinerario.zarpe).format('dddd LL') }}
                          div.mt-n1.text-body-1 {{ $moment(itinerario.zarpe).format('HH:mm A') }}
                        v-col(cols="12" sm="6")
                          div.text-overline.primary--text Recalada
                          div.mt-n1.text-body-1 {{ $moment(itinerario.recalada).format('dddd LL') }}
                          div.mt-n1.text-body-1 {{ $moment(itinerario.recalada).format('HH:mm A') }}
                        v-col(cols="12" sm="6")
                          div.text-overline.primary--text Duración
                          div.mt-n1.text-body-1 {{ duracion(itinerario.duracion) }}
                        v-col(cols="12" sm="6")
                          div.text-overline.primary--text Escalas
                          div.mt-n1.text-body-1 {{ escalas(itinerario?.escalas) }}
                        v-col(cols="12" sm="6")
                          div.text-overline.primary--text Nave
                          div.mt-n1.text-body-1 {{ itinerario.recurso.nombre }}
                        v-col(cols="12" sm="6")
                          div.text-overline.primary--text(v-if="disponibilidad(itinerario)") Disponible
                          div.text-overline.error--text(v-else) No hay cupo
                          div.mt-n1
                            v-tooltip(top)
                              template(v-slot:activator="{ on, attrs }")
                                v-chip.mx-3(:color="color(itinerario, 'pax')" v-bind="attrs" v-on="on")
                                  div(v-if="itinerario.butacas")
                                    v-icon(left) mdi-seat-recline-extra
                                    span {{ itinerario?.disponibilidad['2'] || 0 }}
                                  div(v-else)
                                    v-icon(left) mdi-account
                                    span {{ itinerario?.disponibilidad['1'] || 0 }}
                              span Personas
                            v-tooltip(top)
                              template(v-slot:activator="{ on, attrs }")
                                v-chip.mx-3(:color="color(itinerario, 'veh')" v-bind="attrs" v-on="on")
                                  v-icon(left) mdi-car
                                  span {{ Math.floor(itinerario?.disponibilidad['3']/5) || 0 }}
                              span Vehículos
                              span ({{ itinerario?.disponibilidad['3'] }} metros lineales)
                    v-fade-transition(v-if="!disponibilidad(itinerario)")
                      v-overlay.text-center(v-if="hover" absolute color="error" opacity="0.90" z-index="1")
                        v-icon(x-large color="white") mdi-alert-circle
                        div.text-h5.text-center.color-text No quedan cupos
                        div.text-h6.text-center.color-text Considere revisar otras fechas de viaje
                    v-fade-transition(v-else-if="bloqueo_dinamico(itinerario)")
                      v-overlay.text-center(v-if="hover" absolute color="warning" opacity="0.90" z-index="1")
                        v-icon(x-large color="white") mdi-alert-circle
                        div.text-h5.text-center.color-text Últimos cupos disponibles
                        div.text-h6.text-center.color-text Solo puede adquirirlos <u>presencialmente</u> en nuestras oficinas.
                        div.text-body-1.text-center.color-text Sujeto a disponibilidad.


    v-card-actions
      v-btn.mx-3.mb-3(color="primary" @click="$emit('prev')", :disabled="loading")
        v-icon(left) mdi-chevron-left
        | Volver
      v-spacer
      v-btn.mx-3.mb-3(color="secondary" @click="validar", :loading="loading" :disabled="sin_viaje")
        | Continuar
        v-icon(right) mdi-chevron-right
    // v-card-text(v-if="sin_viaje")
      v-card(@click="$emit('prev')")
        v-img(src="../../assets/images/banner.png")
</template>

<script>

import OrigenDestino from '@/components/UI/OrigenDestino'

export default {
  name: 'Itinerarios',

  components: {
    OrigenDestino
  },

  props: {
    value: {
    }
  },

  data: () => ({
    seleccionado: [null, null],
    tab: 0,
    errores: [false, false],
    loading: false
  }),

  watch: {
    itinerarios (val) {
      if (val === null) {
        this.seleccionado = [null, null]
      }
    },

    seleccionado: {
      deep: true,
      handler (val) {
        if (val === undefined) {
          this.$store.commit('SET_ITINERARIO', [null, null])
          return
        } else {
          let it = []
          for (let i = 0; i < val.length; i++) {
            if (val[i] !== null && val[i] !== undefined) {
              it.push(this.itinerarios[i][val[i]])
              this.it_error(val[i], false)
            } else {
              it.push(null)
            }
          }
          this.$store.commit('SET_ITINERARIO', it)
          // this.$store.commit('SET_PRERESERVA', [null, null])
          this.$store.commit('LIMPIAR_BUTACAS')
        }
        this.$forceUpdate()
      }
    }
  },

  computed: {
    origen () {
      if (this.tab === 0) {
        return this.$store.state.origen
      } else {
        return this.$store.state.destino
      }
    },

    destino () {
      if (this.tab === 0) {
        return this.$store.state.destino
      } else {
        return this.$store.state.origen
      }
    },

    itinerarios () {
      return this.$store.state.itinerarios
    },

    itinerario () {
      return this.$store.state.itinerario
    },

    deshabilitar () {
      let count = 0
      for (let i = 0; i < this.itinerario.length; i++) {
        if (this.itinerario[i] !== null && this.itinerario[i] !== undefined) {
          count++
          this.it_error(i, false)
          continue
        }
        this.it_error(i, true)
      }

      return count < this.itinerarios.length
    },

    tipo_viaje () {
      return this.$store.state.tipo_viaje
    },

    pasajeros () {
      return this.$store.state.pasajerosArr.length
    },

    vehiculos () {
      return this.$store.state.vehiculosArr.length
    },

    sin_viaje () {
      if (!this.itinerarios) {
        return true
      }

      if (this.tipo_viaje === 'vuelta') {
        if (!this.itinerarios[1] || this.itinerarios[1].length === 0) {
          return true
        }
      }

      if (!this.itinerarios[0] || this.itinerarios[0].length === 0) {
        return true
      }

      return false
    }
  },

  mounted () {
    this.setear()
  },

  methods: {
    escalas (escalas) {
      if (!escalas) {
        return 'Sin escalas'
      }
      if (escalas === 1) {
        return '1 escala'
      }

      return `${escalas} escalas`
    },

    duracion (minutes) {
      const hours = Math.floor(minutes / 60)
      const mins = minutes % 60

      if (hours > 0) {
        return `${hours} horas ${mins} minutos`
      }

      return `${mins} minutos`
    },

    it_error (it, flag) {
      this.errores[it] = flag
    },

    setear () {
      if (this.itinerario && this.itinerario.length > 0 && this.itinerarios && this.itinerarios.length > 0) {
        for (let j = 0; j < this.itinerario.length; j++) {
          if (!this.itinerario[j]) {
            continue
          }
          const itinerario = this.itinerario[j].id
          for (let i = 0; i < this.itinerarios[j].length; i++) {
            if (itinerario === this.itinerarios[j][i].id) {
              if (!this.bloqueo_dinamico(this.itinerarios[j][i])) {
                this.seleccionado[j] = i
              }
              break
            }
          }
        }
      }
      this.$forceUpdate()
    },

    tipo (t) {
      if (t === 1) {
        return 'vuelta'
      } else {
        return 'ida'
      }
    },

    ruta (t) {
      if (t === 1) {
        return this.$store.state.destino.nombre + ' - ' + this.$store.state.origen.nombre
      }
      return this.$store.state.origen.nombre + ' - ' + this.$store.state.destino.nombre
    },

    disponibilidad (it, tipo) {
      if (!it || !it.disponibilidad) {
        return false
      }

      if (tipo === 'pax') {
        return (this.pasajeros <= it.disponibilidad['1']) || (this.pasajeros <= it.disponibilidad['2'])
      }

      if (tipo === 'veh') {
        return (this.vehiculos <= it.disponibilidad['3']/5)
      }

      return (this.vehiculos <= it.disponibilidad['3']/5) && ((this.pasajeros <= it.disponibilidad['1']) || (this.pasajeros <= it.disponibilidad['2']))
    },

    bloqueo_dinamico (it, tipo) {
      if (!it.bloqueo_dinamico) {
        return false
      }

      switch (tipo) {
        case 'pax': return it.bloqueo_dinamico['1'] || it.bloqueo_dinamico['2'] || false
        case 'veh': return it.bloqueo_dinamico['3'] || false
        default:
          return ((it.bloqueo_dinamico['1'] || it.bloqueo_dinamico['2']) && this.pasajeros > 0) ||
                (it.bloqueo_dinamico['3'] && this.vehiculos > 0)
      }
    },

    color (it, tipo) {
      if (this.bloqueo_dinamico(it, tipo)) {
        return 'warning'
      }
      if (!this.disponibilidad(it, tipo)) {
        return 'error'
      }

      return 'primary'
    },

    validar () {

      // Validar que estén todos los itinerarios seleccionados
      if (this.deshabilitar) {
        let txt = 'Seleccione el itinerario'
        if (this.tipo_viaje === 'vuelta') {
          txt = 'Seleccione los itinerarios de ida y de vuelta'
        }
        this.$store.dispatch('notify', {
          color: 'error',
          text: txt,
          icon: 'mdi-alert-circle',
          timeout: 5000
        })
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
        this.animacion()
        this.$forceUpdate()
        return
      }

      // Validar que la vuelta sea posterior a la ida
      if (this.tipo_viaje === 'vuelta') {
        const ida = this.$moment(this.itinerario[0].fecha)
        const vuelta = this.$moment(this.itinerario[1].fecha)

        if (ida.isAfter(vuelta)) {
          this.$store.dispatch('notify', {
            color: 'error',
            text: 'El viaje de vuelta debe ser posterior al de ida',
            icon: 'mdi-alert-circle',
            timeout: 5000
          })
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          })
          return
        }
      }

      this.continuar()
    },

    animacion () {
      const tabs = this.$refs.divtab
      if (!tabs || !tabs.length) {
        return
      }

      for (let t = 0; t < tabs.length; t++) {
        if (this.errores[t]) {
          tabs[t].classList.add('animate__animated', 'animate__shakeX', 'animate__delay-1s')
        }
      }
      setTimeout(() => {
        const tabs = this.$refs.divtab
        if (!tabs || !tabs.length) {
          return
        }

        for (let t = 0; t < tabs.length; t++) {
          tabs[t].classList.remove('animate__animated', 'animate__shakeX', 'animate__delay-1s')
        }
      }, 3000)
    },

    async continuar () {
      this.$emit('next')
    }
  }
}

</script>

<style type="text/css">
.it-borde-sec {
  padding: 10px !important;
}

.it-borde-act {
  border-color: #38c68b !important;
  border-width: medium !important;
  padding: 8px !important;
  background-color: #dddddd !important;
}

.linea {
  border-bottom: dashed;
  border-width: 1px;
}

.flotar {
  position: relative;
  top: -20px;
  width: fit-content;
  background: white;
}

.color-text {
  color: #333333;
}

</style>
