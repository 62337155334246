<template lang="pug">
  div(class="tel-input")
    div(class="country-code")
      v-select(outlined, v-model="countryCode", label="País", :items="paises", item-text="name", item-value="dialCode", @change="onChangeCountryCode", return-object, v-bind:class="{'hide-arrow': hideArrow}", :disabled="disabled || loading", :loading="loading", :prepend-icon="prependIcon", :readonly="readOnly" :dense="dense")
        template(v-slot:selection)
          div.vti__flag(:class="activeCountry.iso2.toLowerCase()")
        template(v-slot:item="data")
          span.vti__flag(:class="data.item.iso2.toLowerCase()")
          span {{ data.item.name }} {{ `+${data.item.dialCode}` }}
    v-text-field(outlined, :append-outer-icon="appendOuterIcon", type="tel", :label="label", placeholder="987654321", :prefix="prefix", :value="value", v-model="phone", @input="onInput", @change="onChange", :error="error", :error-messages="errorMsg", @click:append-outer="onClickAppendOuterIcon", v-mask="'##############'", :disabled="disabled || loading", :append-icon="appendIcon" @click:append="onClickAppend", :readonly="readOnly" :dense="dense")
</template>

<script>
import { parsePhoneNumber } from 'awesome-phonenumber'
import allCountries from '@/assets/all-countries'

export default {
  name: 'TelInput',
  props: {
    value: {
      default: () => '',
      type: String
    },
    appendOuterIcon: {
      type: String,
      default: () => null
    },
    hideArrow: {
      type: Boolean,
      default: false
    },
    errorManual: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    defaultCountry: {
      // Default country code, ie: 'CL'
      // Will override the current country of user
      type: String,
      default: () => undefined
    },
    preferredCountries: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: () => 'WhatsApp de contacto'
    },
    prependIcon: {
      type: String,
      default: () => undefined
    },
    appendIcon: {
      type: String,
      default: () => undefined
    },
    readOnly: {
      type: Boolean,
      default: () => false
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    paises: [],
    countryCode: null,
    activeCountry: { iso2: '' },
    prefix: '',
    phone: '',
    error: false,
    errorMsg: '',
    loading: false
  }),

  computed: {
    sortedCountries () {
      // Sort the list countries: from preferred countries to all countries
      const preferredCountries = this.getCountries(
        this.preferredCountries
      ).map(country => ({ ...country, preferred: true }))
      return [...preferredCountries, ...allCountries]
    }
  },

  watch: {
    value (value) {
      // console.log('watch value: ', value)
      if (value && value[0] === '+') {
        this.initializeCountry()
      }
    },

    phone (newValue) {
      // console.log('watch phone: ', newValue, oldValue)
      if (newValue) {
        const telCompleto = '+' + this.activeCountry.dialCode + newValue
        const tel = parsePhoneNumber(telCompleto)
        this.validar(tel)
        /* if (code) {
          this.activeCountry = this.findCountry(code) || this.activeCountry
          console.log('Active Country: ', this.activeCountry)
        } */
      }
      // Reset the cursor to current position if it's not the last character.
      /* if (oldValue && this.cursorPosition < oldValue.length) {
        this.$nextTick(() => {
          setCaretPosition(this.$refs.input, this.cursorPosition)
        })
      } */
    },

    errorManual (flag) {
      if (flag) {
        this.error = true
        this.errorMsg = 'Requerido'
      } else {
        this.error = false
        this.errorMsg = ''
      }
    }
  },

  mounted () {
    this.paises = this.sortedCountries
    // console.log('mounted: loading =', this.loading)
    this.initializeCountry()
  },

  methods: {
    initializeCountry () {
      // console.log('initializeCountry: loading =', this.loading)
      /* if (this.loading) {
        return
      } */
      // console.log('value: ', this.value)
      this.loading = true
      return new Promise((resolve) => {
        if (this.value && this.value[0] === '+') {
          const phone = parsePhoneNumber(this.value)
          const activeCountry = phone.regionCode
          // console.log('Active Country: ', activeCountry)
          // console.log('Phone: ', phone)
          if (activeCountry) {
            this.phone = phone.number.significant
            this.$emit('input', this.phone)
            this.validar(phone)
            this.countryCode = this.findCountry(activeCountry)
            this.choose(this.countryCode)
            this.loading = false
            resolve()
            return
          }
        }

        if (this.defaultCountry) {
          this.countryCode = this.findCountry(this.defaultCountry)
          if (this.countryCode) {
            this.choose(this.countryCode)
            this.loading = false
            resolve()
            return
          }
        }

        const fallbackCountry = this.findCountry('CL')

        this.getCountry()
          .then((res) => {
            this.countryCode = this.findCountry(res) || this.activeCountry
            this.choose(this.countryCode)
          })
          .catch(() => {
            this.countryCode = fallbackCountry
            this.choose(fallbackCountry)
          })
          .finally(() => {
            this.loading = false
            resolve()
          })
      })
    },

    validar (ph) {
      if (!ph) {
        return false
      }

      if (ph.valid) {
        this.errorMsg = ''
        this.error = false
        this.$emit('on-error', false)
        return true
      } else {
        this.errorMsg = 'Número no válido'
        this.error = true
        this.$emit('on-error', true)
        return false
      }
    },

    onInput () {
      this.$emit('input', this.phone)
    },

    onChange () {
      // console.log('Teléfono: ' + this.phone)
    },

    onChangeCountryCode () {
      this.choose(this.countryCode, true)
      const telCompleto = '+' + this.activeCountry.dialCode + this.phone
      const tel = parsePhoneNumber(telCompleto)
      this.validar(tel)
    },

    choose (country) {
      this.activeCountry = country || this.activeCountry || {}
      this.prefix = '+' + this.activeCountry.dialCode
      const data = {
        area: this.activeCountry.dialCode,
        phone: this.phone,
        country: this.activeCountry.iso2
      }
      this.$emit('on-change-country', data)
    },

    findCountry (iso = '') {
      return allCountries.find(
        country => country.iso2 === iso.toUpperCase()
      )
    },

    getCountry () {
      if (!process.browser) {
        return ''
      }

      const controller = new AbortController()
      const timeoutId = setTimeout(() => {
        controller.abort()
      }, 5000)

      return fetch('https://ip2c.org/s', { signal: controller.signal })
        .then(response => response.text())
        .then((response) => {
          clearTimeout(timeoutId)
          const result = (response || '').toString()

          if (!result || result[0] !== '1') {
            throw new Error('unable to fetch the country')
          }

          return result.substr(2, 2)
        })
    },

    getCountries (list = []) {
      return list
        .map(countryCode => this.findCountry(countryCode))
        .filter(Boolean)
    },

    onClickAppendOuterIcon (event) {
      this.$emit('append-outer', event)
    },

    onClickAppend (event) {
      this.$emit('append', event)
    }
  }
}
</script>

<style src="@/assets/sprites.css"></style>
<style lang="scss">
.vti__flag {
  margin-right: 8px;
}

.tel-input {
  display: flex;
  align-items: center;

  .country-code {
    width: 100px;
    .v-select__selections {
      justify-content: center
    }
    .hide-arrow .v-input__append-inner {
      display: none
    }
  }

  li.last-preferred {
    border-bottom: 1px solid #cacaca;
  }

  .v-text-field {
    .v-select__selections {
      position: relative;
      .vti__flag {
        position: absolute;
        margin-left: 18px;
      }
    }
    &--outlined {
      .v-select__selections {
        .vti__flag {
          margin-left: auto;
          margin-right: 0px;
        }
      }
    }
  }
}
</style>
