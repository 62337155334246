<template lang="pug">
  v-card(outlined)
    v-card-title Vehículos
    v-card-text
      div.mb-6.text-h6.font-weight-light(v-if="vehiculos.length > 0") Complete los datos de los vehículos:
      div.mb-6.text-h6.font-weight-light(v-else) Sin vehículos
      v-expansion-panels(v-model="activo" style="z-index: 0")
        v-expansion-panel(v-for="(vehiculo, v) in vehiculos", :key="v")
          v-expansion-panel-header(:disable-icon-rotate="tiene_error(v) !== 0")
            v-row(align="center")
              v-col.d-flex(cols="auto")
                v-icon.mr-2(color="secondary") {{ icono(vehiculo) }}
                div.primary--text.text-body-1.font-weight-medium Vehículo {{ v + 1 }}
            template(v-if="tiene_error(v) === 0" v-slot:actions)
              v-icon $expand
            template(v-else-if="tiene_error(v) === 1" v-slot:actions)
              v-icon(color="error") mdi-alert-circle
            template(v-else v-slot:actions)
              v-icon(color="success") mdi-check
          v-expansion-panel-content
            v-form(:ref="'form_vehiculo_' + v" :disabled="disabled")
              v-row(align="center")
                v-col(cols="12" sm="6")
                  v-select(label="Tipo" v-model="vehiculo.tipo" item-text="nombre" item-value="id" :items="tipos" filled color="secondary" required :rules="rules(true)" :validate-on-blur="!first_time" @blur="onBlur($event, v)" @change="onChange")
                v-col(cols="12" sm="6")
                  v-text-field(label="Patente" v-model="vehiculo.patente" filled color="secondary" :required="!vehiculo.sinpatente" :rules="rules(!vehiculo.sinpatente)" :validate-on-blur="!first_time" @blur="onBlur($event, v)" @change="onChange" :disabled="vehiculo.sinpatente")
                    template(v-slot:append)
                      div.align-center.d-flex
                        div.mr-2.mt-n2.caption
                          div Rent a car /
                          div Sin patente
                        v-simple-checkbox(v-model="vehiculo.sinpatente" color="primary" @input="onInputSinPatente(vehiculo)")
                v-expand-transition(v-if="vehiculo.sinpatente")
                  v-col.pt-0(cols="12")
                    div.mt-n6.d-flex.text-center.justify-center
                      v-icon.mr-2(color="warning") mdi-alert
                      div.body-1 La patente se exigirá al momento del embarque
                      v-icon.ml-2(color="warning") mdi-alert
              v-row(align="center")
                v-col(cols="12" sm="6")
                  v-select(label="Conductor" v-model="vehiculo.conductor" filled color="secondary" :items="conductores" required :rules="rules(true)" :validate-on-blur="!first_time" item-text="nombre" item-value="id" @change="onChange")
                v-col(v-if="vehiculo.tipo !== 'vmayor'" cols="12" sm="6")
                  v-checkbox.mt-0(label="Carro de arrastre" v-model="vehiculo.carro" @change="onChange")
                v-expand-transition
                  v-col(v-if="vehiculo.carro || vehiculo.tipo === 'vmayor'" cols="12" sm="6")
                    v-text-field(:label="label_largo(vehiculo)" v-model="vehiculo.largo" filled color="secondary" required :rules="rules(true, 'positivo')" :validate-on-blur="!first_time" placeholder="6.5" suffix="m" type="number" min="0" max="20" @change="onChange")

</template>

<script>

export default {
  name: 'WidgetVehiculos',

  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },

  components: {
  },

  data: () => ({
    first_time: true,
    activo: 0,
    tipos: [
      {
        id: 'vmenor',
        nombre: 'Vehículo menor',
        icono: 'mdi-car'
      },
      {
        id: 'moto',
        nombre: 'Motos y motonetas',
        icono: 'mdi-motorbike'
      },
      {
        id: 'motorhome',
        nombre: 'Motorhome',
        icono: 'mdi-rv-truck'
      },
      {
        id: 'vmayor',
        nombre: 'Vehículo mayor',
        icono: 'mdi-truck'
      }
    ]
  }),

  watch: {
  },

  computed: {
    vehiculos () {
      return this.$store.state.vehiculosArr
    },

    conductores () {
      return this.$store.state.pasajerosArr.map(p => {
        return {
          id: p.rut,
          nombre: (p.nombre || '') + ' ' + (p.apellido || '')
        }
      })
    }
  },

  methods: {
    onInputSinPatente (vehiculo) {
      if (vehiculo.sinpatente) {
        vehiculo.patente = ''
      }
    },

    onBlur (event, v) {
      if (v !== undefined && this.vehiculos[v] && this.vehiculos[v].patente) {
        this.vehiculos[v].patente = this.vehiculos[v].patente.toUpperCase().replace(/[^A-Z0-9]/g, '')
      }
    },

    icono (vehiculo) {
      for (let t = 0; t < this.tipos.length; t++) {
        if (this.tipos[t].id === vehiculo.tipo) {
          return this.tipos[t].icono
        }
      }
      return 'mdi-help'
    },

    label_largo (vehiculo) {
      if (vehiculo.tipo === 'vmayor') {
        return 'Largo total'
      }
      return 'Largo carro de arrastre'
    },

    validar () {
      if (this.vehiculos.length === 0) {
        return true
      }
      this.first_time = false
      let validos = []
      this.$store.commit('RESETEAR_CONDUCTORES')
      for (let v = 0; v < this.vehiculos.length; v++) {
        if (this.vehiculos[v].driver) {
          this.vehiculos[v].driver.pIsDriver = true
        }
        if (v === this.activo) {
          validos.push(this.validacion_form_manual(v))
        } else {
          validos.push(this.validacion_manual(v))
        }
      }
      this.$forceUpdate()
      return validos.reduce((a, b) => a && b)
    },

    validacion_form_manual (v) {
      const v1 = this.$refs['form_vehiculo_' + v]
      if (v1) {
        const valido = v1[0].validate()
        this.vehiculos[v].error = !valido
        return valido
      }
      this.vehiculos[v].error = true
      return false
    },

    validacion_manual (v) {
      if (this.vehiculos[v].tipo === '') {
        this.vehiculos[v].error = true
        return !this.vehiculos[v].error
      }
      if (!this.vehiculos[v].patente || !this.vehiculos[v].conductor) {
        this.vehiculos[v].error = true
        return !this.vehiculos[v].error
      }
      if (this.vehiculos[v].tipo === 'vmayor' || this.vehiculos[v].carro) {
        this.vehiculos[v].error = !(this.vehiculos[v].largo > 0)
        return !this.vehiculos[v].error
      }
      this.vehiculos[v].error = false
      return !this.vehiculos[v].error
    },

    /* onChange (change, v) {
      // Conductores:  @change="onChange($event, v)"
      const chofer = this.$store.state.pasajerosArr.find(p => p.rut === change)
      this.$store.state.vehiculosArr[v].driver = chofer
    }, */

    onChange () {
      this.$store.commit('SET_TARIFAS_VIGENTES', false)
    },

    tiene_error (v) {
      if (this.first_time) {
        return 0
      }
      if (this.vehiculos[v].error) {
        return 1
      }
      return 2
    }
  }
}

</script>

<style type="text/css">

</style>
